import { ReactNode } from "react";
import cn from "classnames";
import s from "./FilterCard.module.scss";
import { useTranslation } from "next-i18next";
import { FormatDateRange } from "../../lib/utils/FormatDateRange";

type Props = {
  image?: ReactNode;
  type?: string;
  title: string | ReactNode;
  date?: string;
  startDate?: string;
  location?: string;
  author?: string;
  readTime?: string;
  link: ReactNode;
  children?: ReactNode;
  label?: ReactNode;
  categories?: string[];
  pageType?: string;
};

const FilterCard = ({
  image,
  type,
  title,
  date,
  label,
  startDate,
  location,
  children,
  author,
  readTime,
  categories,
  pageType,
}: Props) => {
  const { t } = useTranslation();

  const variation =
    pageType === "pressReleaseFinderPage" ||
    pageType === "investorEventFinderPage"
      ? "filterCardAlt"
      : "";
  return (
    <div className={cn(s.filterCard, s[variation])}>
      <div>
        {image && (
          <div className={s.imageContainer}>
            <div>
              <figure className={s.figure}> {image}</figure>
            </div>
          </div>
        )}

        {pageType !== "investorEventFinderPage" && type && (
          <p className={cn("label", s.filterCardLabel)}>{type}</p>
        )}

        {/* Place date before header for Press Releases and Investor Events */}
        {(pageType === "pressReleaseFinderPage" ||
          pageType === "investorEventFinderPage") && (
          <ul className={s.details}>
            {(startDate || date) && (
              <>
                {startDate && date ? (
                  <li className={s.date}>{startDate && date}</li>
                ) : date ? (
                  <li className={s.date}>{date}</li>
                ) : null}
              </>
            )}

            {pageType === "pressReleaseFinderPage" &&
              categories?.includes("regulatory") && (
                <li>
                  <span className={s.tag}>
                    {t("regulatory", { ns: "tags" })}
                  </span>
                </li>
              )}

            {pageType === "investorEventFinderPage" && label && (
              <li>{label}</li>
            )}
          </ul>
        )}

        <h3 className={s.title}>{title}</h3>
        {children && <div className={s.body}>{children}</div>}
      </div>

      {/* Keep date and other meta data in its place if it's not Press Release or Investor Event */}
      {pageType !== "pressReleaseFinderPage" &&
        pageType !== "investorEventFinderPage" &&
        (startDate || date || location || author || readTime) && (
          <ul className={s.details}>
            {author && (
              <li>
                <label className={cn("author", s.filterCardLabel)}>
                  {author}
                </label>
              </li>
            )}
            {startDate && date ? (
              <li className={cn("date")}>{FormatDateRange(startDate, date)}</li>
            ) : date ? (
              <li className={cn("date")}>{FormatDateRange(date)}</li>
            ) : null}
            {location && (
              <li className={cn("location")}>{t(location, { ns: "tags" })}</li>
            )}
            {readTime && (
              <li
                className={cn({
                  [s.divider]: (author || date || location) && readTime,
                })}
              >
                {readTime + " " + t("minutesShort")}
              </li>
            )}
          </ul>
        )}
    </div>
  );
};

export default FilterCard;
