import { useRouter } from "next/router";
import React, { useEffect } from "react";
import ListPressReleases from "../../ui/ListPressReleases";
import { DynamicBlocks } from "../DynamicBlock";

const PressreleaseFinderPage = ({ story }) => {
  const router = useRouter();
  const { hero } = story.content;

  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;

      if (data.type === "from_single_iframe") {
        const path = router.asPath.split("?s=")[0];
        const slug = router.asPath.split("?s=")[1];
        const updatedPath = path
          .replace(/^\/_sites/, "")
          .replace(/\/investor/, "");

        console.log("Full router:", {
          asPath: router.asPath,
          pathname: updatedPath,
          slug: slug,
        });

        router.push(
          {
            pathname: updatedPath,
            query: { s: slug },
          },
          undefined,
          { shallow: true }
        );
      }

      // Back to homepage from Disclaimer
      if (data.type === "disclaimer_back_click") {
        const path = router.locale === "sv" ? "/sv" : "/";
        router.push(path);
      }

      // Resolve the issue with opening links in new tab
      if (data.type === "navigate") {
        router.push(data.url);
      }

      // Handle disclaimer acceptance and iframe reload
      if (data.type === "disclaimer_accepted" && data.slug === router.query.s) {
        setTimeout(() => {
          const iframe = document.querySelector("iframe");
          iframe?.contentWindow?.location.reload();
        }, 500);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [router]);

  return (
    <div>
      <DynamicBlocks blocks={hero} />
      <ListPressReleases />
    </div>
  );
};

export default PressreleaseFinderPage;
