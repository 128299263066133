import { ReactNode } from "react";
import cn from "classnames";
import s from "./FeaturedHero.module.scss";
import c from "../../styles/container.module.scss";
import { DetailsList, Detail } from "../ArticleHeading";
import { useTranslation } from "next-i18next";
import { FormatDateRange } from "../../lib/utils/FormatDateRange";

type Props = {
  label?: string;
  title: string;
  preamble?: string;
  byLabel?: string;
  author?: string;
  date?: string;
  startDate?: string;
  readTime?: string;
  location?: string;
  buttons?: ReactNode;
  backgroundImage?: ReactNode;
  noOverlay?: boolean;
};

const FeaturedHero = ({
  label,
  title,
  preamble,
  byLabel,
  author,
  date,
  startDate,
  readTime,
  location,
  buttons,
  backgroundImage,
  noOverlay,
}: Props) => {
  const { t } = useTranslation(["tags"]);

  return (
    <section className={s.featuredHero}>
      {backgroundImage && (
        <>
          <div className={s.imageContainer}>{backgroundImage}</div>
          {!noOverlay && <div className={s.overlay} />}
        </>
      )}
      <div className={cn(c.xWide, s.content)}>
        {label && <p className="label">{t(label, { ns: "tags" })}</p>}
        {title && (
          <h1 className={cn(s.title, { [s.noMarginTop]: !label })}>{title}</h1>
        )}
        {preamble && <p className={s.preamble}>{preamble}</p>}
        {(author || (startDate && date) || readTime || location) && (
          <DetailsList horizontal>
            {author && (
              <Detail>
                {byLabel} <span className={s.author}>{author}</span>
              </Detail>
            )}
            {startDate && date ? (
              <Detail>{FormatDateRange(startDate, date)}</Detail>
            ) : date ? (
              <Detail>{FormatDateRange(date)}</Detail>
            ) : null}
            {readTime && <Detail>{readTime}</Detail>}
            {location && <Detail>{location}</Detail>}
          </DetailsList>
        )}

        {buttons && <div className={s.buttonContainer}>{buttons}</div>}
      </div>
    </section>
  );
};

export default FeaturedHero;
