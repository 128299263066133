import { useRouter } from "next/router";
import React from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { EventFinderStory } from "../../lib/storyblok/types/stories/EventFinderStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useRelations } from "../../lib/storyblok/useRelations";
import {
  initAlgoliaIndecies,
  initAlgoliaSearch,
} from "../../lib/utils/AlgoliaSearchHelpers";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import FeaturedHero from "../../ui/FeaturedHero";
import ImageVaultImage from "../ImageVaultImage";
import Button from "../../ui/Button";
import StoryFinder from "../Nestable/StoryFinder";
import { useTranslation } from "next-i18next";
import HeroBlock from "../Nestable/Hero";
import { splitIntoSections } from "../Layout/AnchorMenu/splitIntoSections";
import AnchorMenu from "../Layout/AnchorMenu/AnchorMenu";
import { history } from "instantsearch.js/es/lib/routers";

type VM = ViewModel<EventFinderStory>;
const indecies = initAlgoliaIndecies();
const searchClient = initAlgoliaSearch();

const EventFinderPage: StoryblokStoryComponent<VM> = ({ story, locale }) => {
  const { getRelation } = useRelations();
  const { t } = useTranslation();
  const {
    anchorMenuButton,
    featuredEvent,
    hideStickyMenu,
    nextEvent,
    noHeroOverlay,
    body,
    activateSearchField,
    hero,
  } = story.content;
  const sections = splitIntoSections(body);
  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;
  const refinements = [
    { id: "eventType", attribute: "types" },
    { id: "product", attribute: "categories" },
    { id: "solution", attribute: "solutions" },
    { id: "location", attribute: "location" },
    { id: "language", attribute: "languages" },
  ];

  const featuredEventStory = featuredEvent
    ? getRelation(featuredEvent)
    : nextEvent;

  const hasFeaturedEvent =
    Boolean(featuredEventStory) && nextEvent !== featuredEvent && featuredEvent;

  // Replace space with 'T' for ISO 8601 format and Append 'Z' to indicate the date is in UTC
  const modifiedStartDate =
    featuredEventStory?.content.startDate &&
    featuredEventStory.content.startDate !== "Z"
      ? featuredEventStory.content.startDate.replace(" ", "T") + "Z"
      : undefined;

  const modifiedEventDate =
    featuredEventStory?.content.eventDate.replace(" ", "T") + "Z";
  interface queryParameters {
    types?: string[];
    categories?: string[];
    solutions?: string[];
    languages?: string[];
  }

  const eventInstantSearchRouting = {
    router: history({
      createURL({ qsModule, routeState, location }) {
        const urlParts = location.href.match(/^(.*?)\/events/);
        const baseUrl = `${urlParts ? urlParts[1] : ""}/`;

        const queryParameters: queryParameters = {};

        if (routeState.types) {
          queryParameters.types = (routeState.types as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.categories) {
          queryParameters.categories = (routeState.categories as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.solutions) {
          queryParameters.solutions = (routeState.solutions as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.languages) {
          queryParameters.languages = (routeState.languages as string[]).map(
            encodeURIComponent
          );
        }
        const queryString = decodeURIComponent(
          qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: "repeat",
          })
        );

        return `${baseUrl}events${queryString}`;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseURL({ qsModule, location }) {
        const {
          types = [],
          categories = [],
          solutions = [],
          languages = [],
        } = qsModule.parse(location.search.slice(1));

        const allTypes = Array.isArray(types) ? types : [types].filter(Boolean);

        const allCategories = Array.isArray(categories)
          ? categories
          : [categories].filter(Boolean);

        const allSolutions = Array.isArray(solutions)
          ? solutions
          : [solutions].filter(Boolean);

        const allLanguages = Array.isArray(languages)
          ? languages
          : [languages].filter(Boolean);

        const uiState: queryParameters = {
          types: allTypes.map(decodeURIComponent),
          categories: allCategories.map(decodeURIComponent),
          solutions: allSolutions.map(decodeURIComponent),
          languages: allLanguages.map(decodeURIComponent),
        };

        return uiState;
      },
    }),

    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[indecies[locale]] || {};

        return {
          types: indexUiState.refinementList?.types,
          categories: indexUiState.refinementList?.categories,
          solutions: indexUiState.refinementList?.solutions,
          languages: indexUiState.refinementList?.languages,
        };
      },

      routeToState(routeState) {
        return {
          [indecies[locale]]: {
            refinementList: {
              types: routeState.types,
              categories: routeState.categories,
              solutions: routeState.solutions,
              languages: routeState.languages,
            },
          },
        };
      },
    },
  };

  return (
    <>
      <InstantSearch
        indexName={indecies[locale]}
        searchClient={searchClient}
        routing={eventInstantSearchRouting}
      >
        <Configure
          hitsPerPage={9}
          attributesToSnippet={["title:40", "content:40", "metaDescription:40"]}
          query={initialValue}
          filters={"storyType:eventPage"}
        />
        {hero?.[0] && !hasFeaturedEvent && (
          <HeroBlock
            {...hero?.[0]}
            anchorLinksMenu={
              sections.length > 1 &&
              !hideStickyMenu && (
                <AnchorMenu
                  sections={sections}
                  button={anchorMenuButton?.[0]}
                />
              )
            }
          />
        )}
        {hasFeaturedEvent && featuredEventStory && (
          <FeaturedHero
            label={
              featuredEventStory.content.eventType[0] &&
              featuredEventStory.content.eventType[0]
            }
            title={featuredEventStory.content.heroTitle}
            preamble={featuredEventStory.content.heroPreamble}
            date={modifiedEventDate}
            startDate={modifiedStartDate}
            location={featuredEventStory.content.eventLocation}
            buttons={
              <Button
                variant="inverted"
                href={resolveSlug(featuredEventStory.full_slug)}
              >
                {t("learn-more")}
              </Button>
            }
            backgroundImage={
              <ImageVaultImage
                sizes="100vw"
                image={featuredEventStory.content.heroImage}
                layout="fill"
              />
            }
            noOverlay={noHeroOverlay}
          />
        )}
        <StoryFinder
          pageType={"eventFinderPage"}
          value={initialValue}
          preamble={body}
          activateSearchField={activateSearchField}
          refinements={refinements}
        />
      </InstantSearch>
    </>
  );
};

export default EventFinderPage;
