import React, { ChangeEvent, HTMLAttributes, useRef, useState } from "react";

import s from "./SearchInput.module.scss";
import cn from "classnames";
import Icon from "../Icon";

export type SearchInputProps = HTMLAttributes<HTMLInputElement> & {
  label: string;
  clearLabel: string;
  placeholder: string;
  type?: "small" | "large";
  onClearClick?: () => void;
};

const SearchInput = ({
  label,
  clearLabel,
  onChange: _onChange,
  type = "large",
  onClearClick,
  ...props
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [empty, setEmpty] = useState(true);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmpty(!event.target.value.length);
    if (_onChange) {
      _onChange(event);
    }
  };
  const clear = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setEmpty(true);
      if (onClearClick) {
        onClearClick();
      }
    }
  };

  const isSmall = type === "small";

  return (
    <label className={cn(s.searchInput, { [s.searchInput_small]: isSmall })}>
      <span className="visuallyHidden">{label}</span>
      <Icon icon="search" width={isSmall ? 14 : 24} />
      <input
        {...props}
        type="search"
        ref={inputRef}
        onChange={onChange}
        className={s.input}
      />
      <button className={s.clear} onClick={clear} disabled={empty}>
        <span className="visuallyHidden">{clearLabel}</span>
        <Icon icon="cancel" width={isSmall ? 14 : 24} />
      </button>
    </label>
  );
};

export default SearchInput;
